<template>
  <v-dialog v-model="showModal" scrollable max-width="600px" @click:outside="handleClose">
    <v-card>
      <v-card-title class="text-subtitile-2 white--text py-1 orange accent-2">
        <v-icon color="white" large>mdi-alert</v-icon>
      </v-card-title>

      <v-card-text class="pt-3 pb-0" v-html="message + confirmText"> </v-card-text>

      <v-card-actions>
        <v-text-field
          v-model.trim="enteredText"
          outlined
          dense
          autofocus
          :disabled="loading"
          :rules="rules"
          :placeholder="confirmText"
          persistent-placeholder
        >
        </v-text-field>
      </v-card-actions>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="error" :loading="loading" :disabled="disabled" @click="handleConfirm">
          {{ label.BTN_CONFIRM }}
        </v-btn>
        <v-btn color="warning" text :disabled="loading" @click="handleClose">
          {{ label.BTN_CANCEL }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapGetters } from 'vuex';
export default {
  props: {
    showModal: { type: Boolean, default: false },
    message: String,
    confirmText: String,
  },
  computed: {
    ...mapGetters({
      loading: 'ui/loading',
      label: 'defined/labels',
    }),
    disabled() {
      if (this.loading) {
        return true;
      }
      if (this.confirmText !== this.enteredText) {
        return true;
      }
      return false;
    },
  },
  data() {
    return {
      enteredText: '',
      rules: [
        // (v) => v || "入力して下さい",
        (v) => v === this.confirmText || '入力内容が一致してません',
      ],
    };
  },
  methods: {
    handleConfirm() {
      if (this.enteredText === this.confirmText) {
        this.enteredText = '';
        this.$emit('click:confirm');
      }
    },
    handleClose() {
      this.enteredText = '';
      this.$emit('click:close');
    },
  },
};
</script>
<style scoped>
div.v-card__text {
  white-space: pre-wrap;
}
</style>
